export default {
    Nav: {
        Home: '官网首页',
        About: '关于我们',
        Game: '游戏产品',
        Joinus: '加入我们',
        contactUs: '联系我们',
        secret:'隐私协议',
        terms:'服务条款',
        safe:'账户安全'
    },
    Footer: {
        cont: "Copyright {'@'} 2011 All Right Reserved 弘娱網絡科技有限公司 版权所有",
    },
    commencont:{
        Coming: '敬请期待'
    },
    homecont: {

    },
    contactus: {
        address:'新界屯門天后路1虢彩星大厦2期9楼',
        title:'弘娱網絡科技有限公司'
    },
    Aboutus:{
        about1: '弘娱網絡公司成立于2023年、 是一家专注于互动娱乐领域的新型互联网文化公司。公司致力于原创精品游戏的创作与研发，并将在游戏等各个不同的文化娱乐领域持续深耕，立足全球发展、创造全新的互动娱乐体验。',
        // about2: '公司拥有业界顶级的研发、营运团队、雄厚的技术实力，公司业务涵盖游戏，动漫等，公司当前多款精品游戏同步研发中，我们将凭借着优秀的团队服务全球用户。',
        // about3: '未来，悠樂游戏将继续开发和发布精品游戏产品，超越用户的期望，为全球各地的玩家带来有趣、令人兴奋的娱乐体验和优质服务。',
    },
    title:{
        title: '弘娱網絡 - 全球手机游戏发行与研发商',
        Keywords: '弘娱網絡,手机游戏,網絡游戏,mobile game,安卓游戏,苹果游戏,ios游戏,android游戏,网络游戏,发行商,海外游戏,香港游戏,谷歌商店,苹果商店,下载,安装,免费游戏.',
        Description:'一家手机游戏研发与发行商。'
    },
    Secret:{
        src:'/static/policy.html'
    },
    Terms:{
        src:'/static/terms.html'
    },
    Account:{
        line1:'如果您要删除爱琳相关的账户信息，请填写以下信息（账户一旦删除无法恢复）',
        line2:'提示：如果您是谷歌账号或Facebook账号登录，则需去游戏内悬浮窗创建账号，再填写账号密码',
        line3:"发送uid/account/password信息至客服邮箱destinyoraclecs{'@'}gmail.com",
        form1:'请输入uid',
        form2:'请输入账户',
        form3:'请输入密码',
        btn2:'删除数据',
        btn3:'取消',
        btn4:'确认'
    }
}
