<template>
  <div class="auto_css">
    <div class="z_img">
      <el-image class="imgw145 imgbg" :src="require('@/assets/ztop.jpg')" />
    </div>
    <div class="us_bg">
      <div class="w1200">
        <div class="title">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? gametitleen : gametitle"
          />
        </div>
        <div class="contetn">
          <div class="gamecont pr">
            <div class="gametitle">
              <div class="gametitle_left">
                <span class="gameicon">
                  <el-image
                    class="imgw145"
                    :src="require(`@/assets/${data.tag}/ICON.png`)"
                  />
                </span>
                <div class="icontitle">
                  <p class="icontitle_p1">{{ data.name || "CCCC名字" }}</p>
                  <p>
                    {{
                      data.describe ||
                      "游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍游戏介绍"
                    }}
                  </p>
                </div>
              </div>
              <div class="gametitle_right">
                <el-image
                  class="imgw145 btnimg"
                  :src="require('@/assets/iosdd.png')"
                  @click="handleDownload('1')"
                />
                <el-image
                  class="imgw145 btnimg"
                  :src="require('@/assets/aidd.png')"
                  @click="handleDownload('2')"
                />
              </div>
            </div>
            <div class="five">
              <ul>
                <li>
                  <div class="pr-img">
                    <el-image
                      class="imgw145"
                      :src="require(`@/assets/${data.tag}/1.jpg`)"
                    />
                    <!-- <span class="pa">游戏名字</span> -->
                  </div>
                </li>
                <li>
                  <div class="pr-img">
                    <el-image
                      class="imgw145"
                      :src="require(`@/assets/${data.tag}/2.jpg`)"
                    />
                  </div>
                </li>
                <li>
                  <div class="pr-img">
                    <el-image
                      class="imgw145"
                      :src="require(`@/assets/${data.tag}/3.jpg`)"
                    />
                  </div>
                </li>
                <li>
                  <div class="pr-img">
                    <el-image
                      class="imgw145"
                      :src="require(`@/assets/${data.tag}/4.jpg`)"
                    />
                  </div>
                </li>
                <li>
                  <div class="pr-img">
                    <el-image
                      class="imgw145"
                      :src="require(`@/assets/${data.tag}/5.jpg`)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: `ContactUs`,
  setup() {
    const gametitle = require("@/assets/gametitle.png");
    const gametitleen = require("@/assets/en/gametitle.png");
    const autoimgyn = sessionStorage.getItem("lang");

    const url = window.location.href;
    const arr = url.split("id=");
    const id = arr[1];
    const data = ref();

    if (id == "ailin") {
      data.value = {
        tag: "ailin",
        name: "Destiny Oracle",
        gameType: "An idle anime RPG with beauties",
        iosUrl: "",
        googleUrl: "",
        describe:
          "In the Land of Eileen, become the commander to explore ancient relics with heroes you summon.Features:Fantastic game scenes, imaginative land;Upgrade heroes, listen to their stories;",
      };
    }

    const handleDownload = (type) => {
      if (type === "1") {
        // ios
        if (data.value.iosUrl) {
          window.open(data.value.iosUrl);
        }
      } else if (type === "2") {
        // 谷歌
        if (data.value.googleUrl) {
          window.open(data.value.googleUrl);
        }
      }
    };
    return { gametitleen, gametitle, autoimgyn, data, handleDownload };
  },
};
</script>

<style lang="less" scoped>
.gameicon {
  background: url("../../../src/assets/gameiconk.png");
  width: 170px;
  height: 170px;
  padding: 15px;
  display: inline-block;
  float: left;
}
.icontitle {
  width: 500px;
  float: left;
}
.gametitle {
  height: 250px;
}
.icontitle p {
  margin-top: 10px;
  margin-left: 50px;
  font-size: 20px;
  line-height: 35px;
}
.icontitle p.icontitle_p1 {
  font-size: 30px;
  font-weight: bold;
}
.gametitle_right {
  float: right;
  width: 290px;
}
.btnimg {
  margin-bottom: 15px;
}
.five {
  ul {
    li {
      width: 210px;
      height: 373px;
    }
  }
}
</style>
