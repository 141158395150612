export default {
    Nav: {
        Home: 'Home',
        About: 'About',
        Game: 'Game',
        Joinus: 'Join Us',
        contactUs: 'Contact Us',
        secret:'Policy',
        terms:'Terms',
        safe:'Account Safety'
    },
    Footer: {
        cont: "Copyright {'@'} 2023 WEIYU NETWORK  TECHNOLOGY CO., LIMITED. All Rights Reserved."
    },
    commencont:{
        Coming: 'Coming soon'
    },
    contactus: {
        address:'9th Floor, Phase 2, Guo Choi Sing Building, 1 Tin Hau Road, Tuen Mun, New Territories',
        title:'HONGYU NETWORK TECHNOLOGY CO., LIMITED'
    },
    Aboutus:{
        about1: 'Founded in 2023, Hongyu Network Technology Co., Ltd. is an Internet company specializing in the field of interactive entertainment. The company focuses on original games R&D, and will continue to improve its products and services, providing new interactive entertainment experience to global players.',
        // about2: 'Our company has industry-leading R&D and operation teams, specializing in games and animations. Mutliple high quality games are in development. We will stay commited to offering professional services for global users.',
        // about3: 'In the future, YouLe Fun will continue to develop the absolute best products possible, ones that exceed users’ expectations and bring fun, exciting entertainment and high-quality services to players everywhere.',
    },
    title:{
        title: 'HONG YU GAMES',
        Keywords: 'HONG YU GAMES,mobile games,netgame,android,apple game,ios game,publisher,overseas game,hong kong games,google play games,appstore,download,install,Samsung,huawei,oppo,vivo,meta,vr game, war game,strategy game, revenge, war strategy, free games, online games, mobile app, arabic game,RTS, MMO,world game',
        Description:'A mobile game develop and publisher。'
    },
    Secret:{
        src:'/static/policy-en.html'
    },
    Terms:{
        src:'/static/terms-en.html'
    },
    Account:{
        line1:'If you want to delete your account, please fill in (the deleted account will not be recovered):',
        line2:'Reminder: If you log into through Google or Facebook, please create your account name and password in Account Management.',
        line3:"Please send your UID, account name and password to destinyoraclecs{'@'}gmail.com",
        form1:'please input your uid',
        form2:'please input your account',
        form3:'please input your password',
        btn1:'Delete Account',
        btn2:'Delete data',
        btn3:'Cancel',
        btn4:'Confirm'
    }
}
