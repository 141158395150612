<template>
  <div class="auto_css">
    <div class="z_img">
      <el-image class="imgw145 imgbg" :src="require('@/assets/ztop.jpg')" />
    </div>
    <div class="auto_bg">
      <div class="w1400">
        <div class="title">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? zautoen : zauto"
          />
        </div>
        <div class="contetn">
          <div class="w900 contp">
            <p>
              {{ $t(`Aboutus.about1`) }}
            </p>
            <p>
              {{ $t(`Aboutus.about2`) }}
            </p>
            <!-- <p>
              {{ $t(`Aboutus.about3`) }}
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `auto`,
  setup() {
    const zauto = require("@/assets/zauto.png");
    const zautoen = require("@/assets/en/zauto.png");
    const autoimgyn = sessionStorage.getItem("lang");
    return { zautoen, zauto, autoimgyn };
  },
};
</script>

<style scoped>
</style>
